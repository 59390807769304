<template>
    <div>
        <section class="product">
            <div class="inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">{{producto.name}}</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <router-link to="/productos" class="breadcrumbs_item">Productos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">{{producto.name}}</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="content_block padded rounded white">
                    <div class="product_sheet grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_2">
                            <div class="product_sheet_slideshow grid_col_box">
                                <div class="slider_module">
                                    <VitySlide
                                        :dat="slider"
                                        @click="(img) => {/**abrir fotografia */}"
                                    />
                                </div>
                            </div>
                            <div class="product_sheet_gallery grid_col_box">
                                <div v-if="producto.img" class="product_sheet_gallery_item">
                                    <figure>
                                        <img :src="producto.img"  @error="product.img = require('@/assets/images/img_void.jpg')">
                                    </figure>
                                </div>
                                <div v-if="producto.img2" class="product_sheet_gallery_item">
                                    <figure>
                                        <img :src="producto.img2"  @error="product.img2 = require('@/assets/images/img_void.jpg')">
                                    </figure>
                                </div>
                                <div v-for="(foto, index) in producto.galery" :key="index" class="product_sheet_gallery_item">
                                    <figure>
                                        <img :src="foto.img"  @error="foto.img = require('@/assets/images/img_void.jpg')">
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2" v-if="producto.id">
                            <div class="product_sheet_data grid_col_box">
                                <div class="product_category">
                                    <span v-if="producto.artist"><router-link :to="`/artistas/${producto.artist.url}`" class="">{{producto.artist.name}}</router-link></span>
                                    <span v-for="categoria in producto.categories" :key="categoria.id"><router-link :to="`/productos?category=${categoria.id}`" class="">{{categoria.name}}</router-link></span>
                                </div>
                                <h2 class="text_xl text_700 mb10">{{producto.name}}</h2>
                                <h3 class="text_m text_700">{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(producto.price)}}</h3>
                                <p v-if="producto.id == 11" @click="refundPolicy" style="cursor: pointer;margin-top: 15px;text-decoration: underline;">Pólitica de cancelación y devolución</p>
                                <div class="product_sheet_data_variables">
                                    <VityOptions
                                        v-for="(opciones, index) in producto.options"
                                        :key="index"
                                        v-model="opcion[index]"
                                        :type="index"
                                        :options="opciones"
                                    />
                                    <div class="product_add">
                                        <input type="number" min="1" v-model="cantidad" class="product_quantity" />
                                        <div class="add_cart_btn" :class="{disabled: !isAddCarr || this.getStock() === 0}" @click="addCarrito">Añadir al carro</div>
                                    </div>
                                </div>
                                <div v-if="this.getStock() !== false" class="product_stock">
                                    <span class="product_stock_item ok" v-if="this.getStock() > 0">Plazas disponibles: {{ this.getStock() }}</span>
                                    <span class="product_stock_item ko" v-else>No disponible</span>
                                </div>
                                <div class="grid grid_pad shop_details" v-if="producto.has_envio">
                                    <div class="grid_col grid_col_1_2">
                                        <div class="grid_col_box shop_details_item">
                                            <span>
                                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M5 11v1h8v-7h-10v-1c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.089-.743 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-1c-.552 0-1-.448-1-1v-6h-2v-2h7v2h-3zm3 5.8c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-3-2.8h-10v2h.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6zm1-5v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-16-3h8v2h-8v-2z"/></svg>
                                            </span>
                                            <span>
                                                <h4>24-48 h envío ordinario, 48-72 h envío urgente</h4>
                                                <p>En periodo de rebajas y promociones el plazo de entrega puede ser superior al habitual.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product_description" v-html="producto.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import VityOptions from '@/components/VityOptions'
import VitySlide from '@/components/VitySlides'

export default {
    name: 'Producto',
    components: {
        VityOptions,
        VitySlide
    },
    data: function(){
        return{
            producto: {},
            cantidad: 1,
            slider: [],
            opcion: {},
            isAddCarr: true
        }
    },
    methods: {
        get: function(slug){
            axios
                .get(process.env.VUE_APP_URL+`products/${slug}`)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.slider = [];
                        this.producto = rs.data;
                        if(this.producto.img)
                            this.slider.push({
                                img: this.producto.img,
                                returnData: this.producto.img
                            });
                        if(this.producto.img2)
                            this.slider.push({
                                img: this.producto.img2,
                                returnData: this.producto.img2
                            });
                        if(this.producto.galery.length > 0)
                            this.producto.galery.forEach((obj) => {
                                this.slider.push({
                                    img: obj.img,
                                    returnData: obj.img
                                });
                            });
                        if(this.producto.options){
                            this.producto.options = this.types(this.producto.options, 'type');
                            this.opcion = {};
                        }
                    }
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status == 404)
                            this.$router.push('/404');
                    }
                    else
                        this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion';
                })
        },
        addCarrito: function(){
            this.$store.commit('addProduct', {producto: this.producto, cantidad: this.cantidad});
        },
        types: function(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        getStock: function(){
            if(this.producto.stock == null)
                return false;
            return this.producto.stock < 0 ? 0 : this.producto.stock
        },
        refundPolicy: function(){
            document.getElementById('refund_policy').scrollIntoView();
        }
    },
    created: function(){
        this.get(this.$route.params.slug);
    },
    beforeRouteUpdate (to, from, next) {
		this.get(to.params.slug);
		next();
	},
    watch: {
        opcion: function(){
            let temp = true;
            let keys = Object.keys(this.producto.options);
            for (let index = 0; index < keys.length; index++) {
                if(this.opcion[keys[index]] == null)
                    temp = false;
            }
            this.isAddCarr = temp;
        }
    }
}
</script>